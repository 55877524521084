var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-10",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("RESUMO DO CAIXA ENTRE AGÊNCIAS")]),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.valor_abertura",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrent(item.valor_abertura))+" ")]}},{key:"item.entradas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrent(item.entradas))+" ")]}},{key:"item.saidas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrent(item.saidas))+" ")]}},{key:"item.encomendas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrent(item.encomendas))+" ")]}},{key:"item.passagens",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrent(item.passagens))+" ")]}},{key:"item.excessoBagagem",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrent(item.excessoBagagem))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrent(item.total))+" ")]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDateTimeNew(item.data))+" ")]}},{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrent(item.saldo))+" ")]}},{key:"item.valor_transferencia",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrent(item.valor_transferencia))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status == "0" ? "ABERTO" : "FECHADO")+" ")]}}])}),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }