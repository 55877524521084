<template>
  <v-card class="mb-10" flat>
    <v-card-title>RESUMO DO CAIXA ENTRE AGÊNCIAS</v-card-title>
    <v-divider />
    <v-card-text>
      <v-data-table :headers="headers" :items="items" class="elevation-0">
        <template v-slot:item.valor_abertura="{ item }">
          {{ convertCurrent(item.valor_abertura) }}
        </template>
        <template v-slot:item.entradas="{ item }">
          {{ convertCurrent(item.entradas) }}
        </template>
        <template v-slot:item.saidas="{ item }">
          {{ convertCurrent(item.saidas) }}
        </template>
        <template v-slot:item.encomendas="{ item }">
          {{ convertCurrent(item.encomendas) }}
        </template>
        <template v-slot:item.passagens="{ item }">
          {{ convertCurrent(item.passagens) }}
        </template>
        <template v-slot:item.excessoBagagem="{ item }">
          {{ convertCurrent(item.excessoBagagem) }}
        </template>
        <template v-slot:item.total="{ item }">
          {{ convertCurrent(item.total) }}
        </template>
        <template v-slot:item.data="{ item }">
          {{ convertDateTimeNew(item.data) }}
        </template>
        <template v-slot:item.saldo="{ item }">
          {{ convertCurrent(item.saldo) }}
        </template>
        <template v-slot:item.valor_transferencia="{ item }">
          {{ convertCurrent(item.valor_transferencia) }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ item.status == "0" ? "ABERTO" : "FECHADO" }}
        </template>
      </v-data-table>
      <v-divider />
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "@/mixins";
export default {
  props: ["items"],
  mixins: [mixin],
  data: () => ({
    headers: [
      { text: "Agência", value: "nome", sortable: false },
      { text: "Data/Hora", value: "data", sortable: false },
      { text: "Valor de Abertura", value: "valor_abertura", sortable: false },
      { text: "Entradas Avulsas", value: "entradas", sortable: false },
      { text: "Saídas", value: "saidas", sortable: false },
      { text: "Encomendas", value: "encomendas", sortable: false },
      { text: "Passagens", value: "passagens", sortable: false },
      { text: "Exc. de Bagagem", value: "excessoBagagem", sortable: false },
      { text: "Total", value: "total", sortable: false },
      {
        text: "Valor Transferido",
        value: "valor_transferencia",
        sortable: false,
      },
      { text: "Saldo", value: "saldo", sortable: false },
      { text: "Status", value: "status", sortable: false },
    ],
  }),
};
</script>
