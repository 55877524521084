<template>
  <v-card class="mb-10" flat>
    <v-card-title>VALOR DE ENTREGA PELOS MOTORISTAS</v-card-title>
    <v-divider />
    <v-card-text>
      <v-data-table :headers="headers" :items="items" class="elevation-0">
        <template v-slot:item.total="{ item }">
          {{ convertCurrent(item.total) }}
        </template>
      </v-data-table>
      <v-divider />
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "@/mixins";
export default {
  props: ["items"],
  mixins: [mixin],
  data: () => ({
    headers: [
      { text: "Motorista", value: "nome", sortable: false },
      { text: "Valor", value: "total", sortable: false },
    ],
  }),
};
</script>
