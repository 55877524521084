<template>
  <v-container class="size mt-9">
    <v-subheader class="title mb-4 text-uppercase pa-0">
      <v-icon class="mr-3" @click="$router.back()">mdi-arrow-left</v-icon>
      {{ "Livro Caixa" }}
    </v-subheader>
    <v-card class="mb-10" outlined>
      <v-toolbar color="primary" dark elevation="0" dense>
        <v-toolbar-title>SELECIONE O PERÍODO</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row dense class="text-center">
          <v-col cols="12" sm="12" md="2">
            <Calendar
              :label="'Data Inicial'"
              @dateValue="search.dateStart = $event"
            />
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <Calendar
              :label="'Data Final'"
              @dateValue="search.dateEnd = $event"
            />
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-autocomplete
              label="Agência"
              v-model="search.id_agencia"
              item-text="nome"
              item-value="id"
              :items="agencias"
              clearable
            />
          </v-col>
          <v-col cols="12" sm="12" md="2" class="mt-2">
            <v-btn block color="green darken-1" dark @click="loadValues()"
              >BUSCAR</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <ListDataItemsResume :items="resumeDrivers" />
    <ListDataItems :items="items" />
    <v-footer fixed>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-label>Caixas: {{ convertCurrent(this.resume.cashboxes) }}</v-label>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-label
            >Enc.entregues p/Motoristas:
            {{ convertCurrent(this.resume.drivers) }}</v-label
          >
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-label>Total: {{ convertCurrent(this.resume.total) }}</v-label>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import Calendar from "@/components/Calendars.vue";
import ListDataItems from "./components/ListDataItems.vue";
import ListDataItemsResume from "./components/ListDataItemsResume.vue";
import mixin from "@/mixins";
export default {
  components: {
    Calendar,
    ListDataItems,
    ListDataItemsResume,
  },
  mixins: [mixin],
  data: () => ({
    resume: {
      total: 0,
      drivers: 0,
      cashboxes: 0,
    },
    dialog: false,
    items: [],
    resumeDrivers: [],
    agencias: [],
    compareDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    search: {
      dateStart: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateEnd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      id_agencia: null,
    },

    valid: true,
    fieldRules: [(v) => !!v || "Este campo é requerido"],
  }),
  created() {
    this.getAgencias();
    this.loadValues();
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    async resumeValues(responseCashboxes, responseDrivers) {
      this.resume.drivers = responseDrivers.reduce(
        (acc, item) => acc + parseFloat(item.total),
        0
      );
      this.resume.cashboxes = responseCashboxes.reduce(
        (acc, item) => acc + parseFloat(item.total),
        0
      );
      this.resume.total = this.resume.drivers + this.resume.cashboxes;
    },
    async getAgencias() {
      const response = await this.$http.get("/agencias");
      this.agencias = response.data;
    },
    async loadValues() {
      this.items = [];
      this.$store.dispatch("activeLoader", true);
      const response = await this.$http.post(`/livrocaixa`, this.search);
      this.items = response.data.cashboxes;
      this.resumeDrivers = response.data.resumeDrivers;
      this.resumeValues(response.data.cashboxes, response.data.resumeDrivers);
      this.$store.dispatch("activeLoader", false);
    },
  },
};
</script>

<style lang="css">
.size {
  width: 85%;
}
</style>
